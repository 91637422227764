<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <table-top v-bind="bindChain.tableTop" v-on="onChain.tableTop" />
        </template>
        <template #item.actions="{ item }">
          <table-actions
            v-bind="bindChain.tableActions"
            v-on="onChain.tableActions(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <Form v-model="dialog" v-on="on.form" v-bind="bind.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Form from "@/components/Forms/cruds/tipoContratante.vue";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "CrudTipoContratante",
  mixins: [PageMixin],
  components: {
    Form,
  },
  data: () => ({
    headers: [
      { text: "Código", value: "codigo", align: "left" },
      {
        text: "Tipo contratante",
        value: "tipo_contratante_descripcion",
        align: "left",
      },
      { text: "Fecha creación", value: "fecha_creacion", align: "center" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    tableList: [],
    itemId: "tipo_contratante",
  }),
  computed: {
    items() {
      return this.tableList;
    },
  },
  methods: {
    ...mapActions("NewProduct", [
      "getTipoContratante",
      "CrearTipoContratante",
      "UpdateTipoContratante",
      "deleteTipoContratante",
    ]),

    async setup() {
      this.loading = true;
      try {
        const response = await this.getTipoContratante();
        this.tableList = response;
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loading = false;
    },

    saveMethod() {
      return this.CrearTipoContratante;
    },
    updateMethod() {
      return this.UpdateTipoContratante;
    },
    deleteMethod() {
      return this.deleteTipoContratante;
    },
  },
};
</script>
