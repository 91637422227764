<template>
  <form-Modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <v-text-field
        v-model="form.codigo"
        name="codigo"
        label="Código"
        placeholder="Nuevo nombre de Codigo"
        :rules="[(v) => !!v || 'El campo es Código requerido.']"
      >
      </v-text-field>
      <v-text-field
        v-model="form.tipo_contratante_descripcion"
        name="tipoContra"
        label="Tipo Contratante"
        placeholder="Nuevo nombre de tipo de contratante"
        :rules="[(v) => !!v || 'El campo nombre es requerido.']"
      >
      </v-text-field>
    </v-form>
  </form-Modal>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
export default {
  name: "TipoContratanteCrudModal",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario tipo de contratante",
      form: {
        tipo_contratante_descripcion: "",
        codigo: "",
      },
    };
  },
};
</script>
